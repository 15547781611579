import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import TransferBanner from '../components/FeatureBanner'
import ListFlow from '../components/ListFlow'
import StepSlide from '../components/StepSlide'
import Card from '../components/Card'
import Question from '../components/Question'
import StartNow from '../components/StartNow'

import Unattended from '../images/unattened-access-main.png'
import UnattendedMobile from '../images/UnattendedAccessBgMobile.png'
import EnjoyIndependent from '../images/enjoy-independent.png'
import CrossDevice from '../images/cross-device.png'
import SafeAndReliable from '../images/safe-and-reliable.png'
import SetAuth from '../images/SetAuth.png'
import SystemSleep from '../images/SystemSleep.png'
import UnattenedAccess from '../images/UnattenedAccess.png'
import ITSupport from '../images/ITSupport.png'
import Telecomm from '../images/Telecomm.png'
import Elearning from '../images/Elearning.png'

const UnattendedAccess = () => {
  const descList = [
    'Remote access even when the target device is unattended.',
    'Easy setup and configuration are beginner-friendly.',
    'End-to-end encryption ensures the highest level of security and privacy.',
  ]

  const flowItemList = [
    {
      img: EnjoyIndependent,
      img_mobile: EnjoyIndependent,
      title: 'Enjoy Independent Work',
      desc: "Whether you need to work remotely on your office computer or provide instant IT support to your colleagues' or clients' devices, Avica's unattended remote access feature ensures a reliable remote connection and enables you to work on your terms and schedule, even when no end-user is present.",
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
    {
      img: CrossDevice,
      img_mobile: CrossDevice,
      title: 'Cross-Device Unattended Access',
      desc: "Seamlessly access remote Windows or Mac computers from either your Windows, macOS, Android, or iOS devices, even when you can't be there in person. Stay connected, productive, and in control, no matter where you are, while saving time and money on transportation.",
      ifRotation: true,
      ifShowDownloadBtn: false,
    },
    {
      img: SafeAndReliable,
      img_mobile: SafeAndReliable,
      title: 'Safe and Reliable',
      desc: "With Avica's end-to-end AES-256 encryption, your data is protected with the utmost security and privacy. Rest easy knowing that your sensitive information remains confidential throughout the remote access process. Avica goes the extra mile by offering a Privacy Mode that ensures the remote computer's screen remains shielded from prying eyes, safeguarding your work privacy.",
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
  ]

  const tab1List = [
    {
      img: SetAuth,
      title: 'Step 1. Set Access Authentication',
      desc: 'Launch Avica on the remote device. Navigate to Settings > Security > Remote Access Authentication, selecting "Enable both temporary and security password for remote connection," and setting a strong fixed password.',
      redirect: false,
    },
    {
      img: SystemSleep,
      title: 'Step 2. Disable System Sleep ',
      desc: 'Then, go to Settings > General > enable "Startup on Boot" on Avica and disable the remote device\'s system automatic sleep.',
      redirect: false,
    },
    {
      img: UnattenedAccess,
      title: 'Step 3. Initiate Unattended Access',
      desc: 'Enter the remote device\'s Avica ID on your local device and tap the "Connect" button. Enter the password when asked for it to initiate the remote session.',
      redirect: true,
    },
  ]

  const More = [tab1List]

  const tabInfoList = [
    {
      tabName: '',
      ifShowSelectBox: true,
      isPlay: true,
    },
  ]

  const cardList = [
    {
      img: ITSupport,
      title: 'IT Support',
      desc: "With unattended remote access, IT professionals can view the user's screen, control their mouse and keyboard, and fix issues as if they were physically present. This hands-on access speeds up problem solving, saving time and resources. It also enables support to assist users in different locations, extending their reach and availability.",
    },
    {
      img: Telecomm,
      title: 'Telecommuting',
      desc: 'Employees can now access their work computers from home or any location outside the office. This flexibility allows employees to independently continue their work without being physically present in the workplace, enabling them to collaborate with colleagues, access important files and data, and perform tasks.',
    },
    {
      img: Elearning,
      title: 'E-learning',
      desc: 'Unattended access in education allows teachers and students to connect virtually for instruction. Teachers can handle teaching tasks by remotely accessing their office PCs, while students can access lab or library devices for learning resources and independent study. This flexible approach encourages collaboration and boosts learning opportunities beyond traditional classrooms.',
    },
  ]

  const faqList = [
    {
      question: 'What is unattended access?',
      answer:
        'Unattended access is the capability to remotely connect and control a device or computer without the need for a person to be physically present at the location.',
    },
    {
      question:
        'What is the difference between remote support and unattended access?',
      answer:
        'Remote support requires the user to be actively involved in granting access and cooperation, while unattended remote access allows for autonomous and unsupervised control of the device.',
    },
    {
      question:
        'What can the connecting user accomplish with Avica Unattended Access?',
      answer:
        'With Avica Unattended Access, they can access remote device data, use software applications, transfer files, record remote sessions, and perform various other tasks remotely. In summary, all features of Avica can be used.',
    },
  ]

  return (
    <Layout pageType="Unattended">
      <TransferBanner
        title="Unattended Access"
        descList={descList}
        photo={Unattended}
        mobile={UnattendedMobile}
      />
      <ListFlow
        featureList={flowItemList}
        title="Access Devices Remotely Without End-User Presence"
        desc="Avica offers a convenient, stable, and secure solution for unattended access."
      />
      <StepSlide
        title="How to Enable Unattended Access with Avica?"
        ifShowTab={false}
        More={More}
        TabInfo={tabInfoList}
        className_title="title"
        guideLink="https://www.avica.link/help-center/how-to-use-unattended-access/"
      />
      <Card
        cardList={cardList}
        title="Cater to Various Unattended Access Needs"
      />
      <Question faqList={faqList} />
      <StartNow pre="Start Your" span="Unattended Access" suf="Journey Now" />
    </Layout>
  )
}

export default UnattendedAccess
